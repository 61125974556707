var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PullTo',{attrs:{"top-load-method":_vm.refresh}},[_c('v-container',[_c('h1',{staticClass:"mb-6"},[_vm._v("Студенти")]),_vm._l((_vm.groups),function(group,key){return _c('v-card',{key:key,staticClass:"mb-10",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(group.name)+" ")]),_c('v-divider'),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":group.students,"items-per-page":-1,"search":_vm.search,"loading":_vm.loading,"loading-text":"Завантаження студентів... Зачекайте, будь ласка","no-data-text":"Немає жодного студента","mobile-breakpoint":"0","hide-default-footer":""},scopedSlots:_vm._u([_vm._l((_vm.tests),function(test,key){return {key:("item.latest_results_keyed." + (test.id)),fn:function(ref){
var value = ref.value;
return [(value)?_c('v-chip',{key:key,staticClass:"font-italic font-weight-bold black--text",style:({
							background: _vm.perc2color(value.result)
						}),attrs:{"to":{
							name: 'result',
							params: {
								resultId: value.id,
							},
						},"label":""}},[_vm._v(" "+_vm._s(value.result)+"% ")]):_vm._e()]}}})],null,true)})],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }