<template>
	<PullTo :top-load-method="refresh">
		<v-container>
			<h1 class="mb-6">Студенти</h1>

			<v-card
				v-for="(group, key) in groups"
				:key="key"
				class="mb-10"
				outlined>

				<v-card-title>
					{{ group.name }}
				</v-card-title>
				<v-divider></v-divider>

				<v-data-table
					:headers="headers"
					:items="group.students"
					:items-per-page="-1"
					:search="search"
					:loading="loading"
					loading-text="Завантаження студентів... Зачекайте, будь ласка"
					no-data-text="Немає жодного студента"
					mobile-breakpoint="0"
					hide-default-footer>

					<template
						v-for="(test, key) in tests"
						v-slot:[`item.latest_results_keyed.${test.id}`]="{ value }">
						<v-chip
							v-if="value"
							:key="key"
							:to="{
								name: 'result',
								params: {
									resultId: value.id,
								},
							}"
							:style="{
								background: perc2color(value.result)
							}"
							class="font-italic font-weight-bold black--text"
							label>
							{{ value.result }}%
						</v-chip>
					</template>

				</v-data-table>
			</v-card>
		</v-container>
	</PullTo>
</template>

<script>
import { mapActions } from 'vuex'
import { map, flatMap, uniqBy, find, filter } from 'lodash'
import { perc2color } from '../utils/perc2color'

import Student from '../models/Student'

import PullTo from '../components/global/PullTo'

export default {
	components: {
		PullTo,
	},
	data: () => ({
		search: '',
		loading: false,
	}),
	computed: {
		students() {
			return Student.all()
		},
		groups() {
			const groups = uniqBy(flatMap(this.students, 'groups'), 'id')
			return groups.map(group => {
				const students = filter(this.students, student => find(student.groups, { id: group.id }))
				return {
					...group,
					students,
				}
			})
		},
		tests() {
			return uniqBy(map(flatMap(this.students, 'latest_results'), 'test'), 'id')
		},
		headers() {
			return [
				{
					text: "Ім'я",
					value: 'full_name',
				},
				...this.tests.map(test => ({
					text: test.name,
					value: `latest_results_keyed.${test.id}`,
				})),
			]
		},
	},
	methods: {
		...mapActions('notification', [
			'notify',
		]),

		perc2color,

		async loadStudents() {
			try {
				this.loading = true
				await Student.fetchGetAll()
			} catch (error) {
				this.notify({
					text: 'Не вдалося завантажити список учнів',
					color: 'error',
				}, { root: true })
			} finally {
				this.loading = false
			}
		},

		async refresh(loaded) {
			try {
				await this.loadStudents()
				loaded('done')
			} catch (error) {
				loaded('fail')
			}
		},
	},
	mounted() {
		this.loadStudents()
	},
}
</script>